<template>
  <section>
    <v-card :dark="$dark.get()" elevation="1">
      <loader v-if="loading"></loader>
      <input
        ref="imgInput"
        type="file"
        hidden
        accept="image/*"
        @change="setImage($event)"
      />
      <v-card-title class="title">
        <p>
          Список продуктов
          {{ withCategory ? "с категориями" : "без категорий" }}
        </p>
        <div class="title__find-block">
          <v-autocomplete
            v-if="withCategory"
            v-model="searchCategory"
            :items="CATEGORY"
            item-text="name"
            item-value="id"
            no-data-text="Ничего не найдено"
            class="mt-3"
            label="Поиск по категории"
            clearable
            @change="findByCategory()"
            @click:clear="clearInput()"
          />
          <v-autocomplete
            v-model="searchProvider"
            :items="PROVIDERS.allProviders"
            item-text="name"
            item-value="id"
            no-data-text="Ничего не найдено"
            class="mt-3"
            label="Поиск по поставщику"
            clearable
            @change="findByProvider()"
            @click:clear="clearInput()"
          />
          <v-text-field
            v-model="findTextByName"
            label="Поиск по названию..."
            class="search-input"
            @keyup.enter="findProductsByName()"
            @input="checkText($event)"
          >
            <v-btn
              v-if="findTextByName"
              slot="append"
              color="red"
              icon
              @click="clearInput()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn
              slot="append"
              color="primary"
              icon
              @click="findProductsByName()"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-text-field>
          <v-text-field
            v-model="findTextByBarcode"
            label="Поиск по штрихкоду..."
            class="search-input"
            @keyup.enter="findProductsByBarcode()"
            @input="checkText($event)"
          >
            <v-btn
              v-if="findTextByBarcode"
              slot="append"
              color="red"
              icon
              @click="clearInput()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn
              slot="append"
              color="primary"
              icon
              @click="findProductsByBarcode()"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-text-field>
        </div>
        <v-switch
          v-model="withCategory"
          :label="withCategory ? 'С категориями' : 'Без категорий'"
          @change="changeProductsType()"
        ></v-switch>
      </v-card-title>
      <v-card-text>
        <p v-if="noFindProducts" class="center">
          По данному запросу ничего не найдено
        </p>
        <v-simple-table v-else fixed-header>
          <thead>
            <tr>
              <th>
                <v-checkbox
                  v-model="all"
                  @change="
                    checks.forEach((el) => {
                      el.state = all;
                    })
                  "
                />
              </th>
              <th v-for="item in items.head" :key="item.id">
                <span>{{ item.name }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items.body"
              :key="item.id + '/' + index"
            >
              <td class="cell">
                <v-checkbox
                  v-model="checks[index].state"
                  @change="all = false"
                />
              </td>
              <td class="cell">{{ item.id }}</td>
              <td
                class="cell cell_img"
                @click="
                  $refs.imgInput.click();
                  imgId = item.id;
                "
              >
                <p v-if="item.images ? item.images.length === 0 : true">?</p>
                <v-img
                  v-else
                  width="50px"
                  height="50px"
                  :src="item.images[0].fileUrl"
                />
              </td>
              <td class="cell">{{ item.name }}</td>
              <td class="cell">
                <p v-for="barcode in item.barcodes" :key="barcode">
                  {{ barcode }}
                </p>
              </td>
              <td class="cell">
                <v-select
                  v-model="item.categoryIds"
                  :label="withCategory ? '' : 'Выберите категорию'"
                  :items="CATEGORY"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  :disabled="withCategory"
                  @blur="changeCategory(item.categoryIds, item.id)"
                ></v-select>
              </td>
              <td class="cell">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="openBalanceModal(item)"
                    >
                      <v-icon>mdi-alarm-panel</v-icon>
                    </v-btn>
                  </template>
                  <span>Баланс продукта</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="openInfoModal(item)"
                    >
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <span>Полная информация</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="goToSingleProduct(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Изменить товар</span>
                </v-tooltip>

                <v-tooltip v-if="item.categoryIds" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteCategory(item.categoryIds[0], item.id)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>Удалить категорию</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions v-if="!findTextByBarcode" class="justify-center">
        <v-pagination
          v-if="pages !== null"
          :value="pages.current + 1"
          :dark="$dark.get()"
          :total-visible="7"
          :length="pages.count"
          @input="changePage($event - 1)"
        ></v-pagination>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="editAllCategoryModal" activator="parent" width="70%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center">
          Редактирование категорий
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="changedCategoryes"
            :items="CATEGORY"
            item-text="name"
            item-value="id"
            no-data-text="Начните поиск"
            class="mt-3"
            label="Выберите категорию"
            clearable
            multiple
            chips
          />
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn color="primary" @click="changeManyCategory(false)"
            >Заменить</v-btn
          >
          <v-btn color="primary" @click="changeManyCategory(true)"
            >Добавить</v-btn
          >
          <v-btn @click="editAllCategoryModal = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <InfoModal :show-modal="infoModal" :product="product" />
    <BalanceModal :show-modal="balanceModal" :product-id="product.id" />
    <DeleteDialog
      :show="showDeleteModal"
      :is-delete-reason="states.length > 0"
      @close-modal="showDeleteModal = !showDeleteModal"
      @delete-modal="deleteModal($event)"
    />
    <bottom-bar
      :active="active"
      :delet="true"
      :edit-category="true"
      :disable="states.length > 1"
      @show="showDeleteModal = true"
      @showEditCategoryModal="editAllCategoryModal = true"
    >
    </bottom-bar>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import InfoModal from "@/components/ProductMVP/InfoModalMVP";
import BalanceModal from "@/components/ProductMVP/BalanceModal";
import DeleteDialog from "@/components/DeleteDialog";
import ShowMessage from "@/Functions/message";
import BottomBar from "@/components/BottomBar";
import api from "@/api";
export default {
  components: {
    Loader,
    InfoModal,
    BalanceModal,
    DeleteDialog,
    BottomBar,
  },
  data() {
    return {
      loading: false,
      withCategory: false,
      showDeleteModal: false,
      editAllCategoryModal: false,
      changedCategoryes: [],
      pageMode: "default",
      currentCategory: {
        name: "Без категории",
        id: 0,
      },
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Фото" },
          { id: 3, name: "Название" },
          { id: 4, name: "Штрихкод" },
          { id: 8, name: "Категории" },
          { id: 9, name: "Действие" },
        ],
        body: [],
      },
      body: {
        pageSize: 50,
        page: 0,
      },
      pages: {
        current: 0,
        count: 0,
      },
      infoModal: false,
      balanceModal: false,
      product: {},
      payload: {},
      findTextByName: "",
      findTextByBarcode: "",
      noFindProducts: false,
      all: false,
      checks: [],
      scroll: null,
      deleteItem: false,
      show: false,
      searchCategory: 0,
      searchProvider: 0,
      imgId: 0,
    };
  },
  computed: {
    ...mapGetters({
      CATEGORY: "Category/PRODUCTS_CATEGORY",
      PRODUCT: "Products/STATE",
      DEPARTMENT: "Department/STATE",
      PROVIDERS: "Providers/STATE",
    }),
    // высчитывает какие продукты выбраны через чекбокс
    states() {
      return this.checks.reduce((accum, x) => {
        if (x.state === true) {
          accum.push(x.id);
        }
        return accum;
      }, []);
    },
    // чтобы показывать кнопку удаления
    active() {
      if (this.states.length > 0) return "active";
      return "";
    },
  },
  watch: {
    pageMode() {
      this.body.page = 0;
    },
  },
  created() {
    this.checkLS();
    this.setCategory();
    this.changePage(this.body.page);
  },
  methods: {
    ...mapActions({
      getCategory: "Category/GET_PRODUCTS_CATEGORY",
      paginatedProducts: "Products/GET_PAGINATED_PRODUCTS_MVP",
      paginatedProductsWithGategory:
        "Products/GET_PAGINATED_PRODUCTS_MVP_WITH_CATEGORY",
      getDepartment: "Department/GET_DEPARTMENT",
      productImage: "Products/ADD_IMAGES",
      getAllProviders: "Providers/GET_ALL_PROVIDERS",
    }),
    async setAll() {
      this.loading = true;
      if (this.withCategory) {
        await this.paginatedProductsWithGategory(this.body);
      } else {
        await this.paginatedProducts(this.body);
      }
      this.items.body = this.PRODUCT.paginatedProductsMVP.content;
      this.pages.current = this.PRODUCT.paginatedProductsMVP.number;
      this.pages.count = this.PRODUCT.paginatedProductsMVP.totalPages;
      this.loading = false;
      this.setChecks();
    },
    async setCategory() {
      this.loading = true;
      await this.getDepartment(this.$root.city);
      if (!this.CATEGORY.length) {
        await this.getCategory();
      }
      if (!this.PROVIDERS.allProviders.length) {
        this.getAllProviders();
      }
      this.loading = false;
    },
    changeProductsType() {
      this.body.page = 0;
      if (this.pageMode === "default") {
        this.setAll();
      }
      if (this.withCategory) {
        sessionStorage.setItem("AdminAyanProducts", true);
      } else {
        sessionStorage.removeItem("AdminAyanProducts");
      }
    },
    async changeCategory(categoryList, productId) {
      if (!categoryList) {
        return;
      }
      const errorList = [];
      this.loading = true;
      for (const categoryId of categoryList) {
        let response = await api.Products.addCategoryToProduct({
          categoryId,
          productId,
        });
        if (response.type) {
          errorList.push(response.type);
        }
      }
      if (errorList.length === 0) {
        ShowMessage("Категория привязана к товару", true);
        this.changePage(this.body.page);
      } else {
        ShowMessage("Категория не привязана");
        this.loading = false;
      }
    },
    // меняет страницу в зависимости от режима
    async changePage(page) {
      this.body.page = page;
      switch (this.pageMode) {
        case "default":
          await this.setAll();
          break;
        case "by name":
          await this.findProductsByName();
          break;
        case "by category":
          await this.findByCategory();
          break;
        case "by provider":
          await this.findByProvider();
          break;
      }
      if (this.scroll) {
        setTimeout(() => {
          window.scrollTo(0, this.scroll);
        }, 100);
      }
    },
    openInfoModal(item) {
      this.product = item;
      this.infoModal = !this.infoModal;
    },
    openBalanceModal(item) {
      this.product = item;
      this.balanceModal = !this.balanceModal;
    },
    // удаляет продукт либо категорию в зависимости от параметра
    async deleteModal(deleteReasonText) {
      if (!deleteReasonText) {
        let response = await api.Products.removeCategoryFromProduct(
          this.payload
        );
        if (!response) {
          ShowMessage("Категория удалена", true);
          this.changePage(this.body.page);
        }
      } else {
        let body = {
          productIds: this.states,
          deleteReason: deleteReasonText,
        };
        let response = await api.Products.deleteMVPProduct(body);
        if (response?.type !== "error") {
          ShowMessage("Товар(ы) удален(ы)", true);
          this.changePage(this.body.page);
        } else {
          ShowMessage(response.data.message);
        }
      }
      this.showDeleteModal = false;
    },
    // Вспомогательные функции назначают текущие данные для удаления
    deleteCategory(categoryId, productId) {
      this.showDeleteModal = true;
      this.payload = {
        categoryId,
        productId,
      };
    },
    // проверка локалСтораж, чтобы грузить с категориями или без
    checkLS() {
      let ls = sessionStorage.getItem("AdminAyanProducts");
      if (ls) {
        this.withCategory = true;
      }
      let mode = JSON.parse(sessionStorage.getItem("AdminAyanProductsMode"));
      if (mode) {
        this.pageMode = mode.pageMode;
        this.body.page = mode.page;
        this.scroll = parseFloat(mode.scroll);
        switch (this.pageMode) {
          case "by name":
            this.findTextByName = mode.findTextByName;
            break;
          case "by category":
            this.searchCategory = mode.searchCategory;
            break;
          case "by provider":
            this.searchProvider = mode.searchProvider;
            break;
        }
      }
    },
    async findProductsByName() {
      if (!this.findTextByName) {
        return;
      }
      this.pageMode = "by name";
      let request = {
        ...this.body,
        name: this.findTextByName,
      };
      this.loading = true;
      let response;
      if (this.withCategory) {
        response = await api.Products.findMVPProductWithCategoryByName(request);
      } else {
        response = await api.Products.findMVPProductWithoutCategoryByName(
          request
        );
      }
      this.items.body = response.content;
      this.pages.current = response.number;
      this.pages.count = response.totalPages;
      this.setChecks();
      this.loading = false;
      if (response.content.length === 0) {
        this.noFindProducts = true;
      } else {
        this.noFindProducts = false;
      }
    },
    async findProductsByBarcode() {
      if (!this.findTextByBarcode) {
        return;
      }
      let response;
      if (this.withCategory) {
        response = await api.Products.findMVPProductWithCategoryByBarcode(
          this.findTextByBarcode
        );
      } else {
        response = await api.Products.findMVPProductWithoutCategoryByBarcode(
          this.findTextByBarcode
        );
      }
      this.items.body = [response];
      this.setChecks();
      if (response.type === "error") {
        this.noFindProducts = true;
      } else {
        this.noFindProducts = false;
      }
    },
    async findByProvider() {
      if (!this.searchProvider) {
        return;
      }
      this.searchCategory = 0;
      this.pageMode = "by provider";
      let body = {
        ...this.body,
        size: this.body.pageSize,
        providerId: this.searchProvider,
      };
      this.loading = true;
      let response = await api.Products.findMVPProductByProvider(body);
      this.items.body = response.content;
      this.pages.current = response.number;
      this.pages.count = response.totalPages;
      this.setChecks();
      this.loading = false;
      if (!response.content.length) {
        this.noFindProducts = true;
      } else {
        this.noFindProducts = false;
      }
    },

    async findByCategory() {
      if (!this.searchCategory) {
        return;
      }
      this.searchProvider = 0;
      this.pageMode = "by category";
      let body = {
        ...this.body,
        categoryId: this.searchCategory,
      };
      this.loading = true;
      let response = await api.Products.findMVPProductByCategory(body);
      this.items.body = response.content;
      this.pages.current = response.number;
      this.pages.count = response.totalPages;
      this.setChecks();
      this.loading = false;
      if (!response.content.length) {
        this.noFindProducts = true;
      } else {
        this.noFindProducts = false;
      }
    },
    // выбор множества товаров для изменения категории
    async changeManyCategory(saveCategories) {
      if (this.changedCategoryes.length === 0) {
        ShowMessage("Категория не выбрана");
        return;
      }
      let body = {
        productIds: this.states,
        categoryIds: this.changedCategoryes,
        saveCategories,
      };
      let response = await api.Products.addCategoryToManyProducts(body);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Категории успешно добавлены", true);
        this.changePage(this.body.page);
      }
      this.editAllCategoryModal = false;
      this.changedCategoryes = [];
    },
    // если убрали весь текст из ввода возвращает исходные продукты
    checkText(text) {
      if (!text) {
        this.clearInput();
      }
    },
    // переход на страницу с отдельным продуктом
    goToSingleProduct(productId) {
      let body = {
        pageMode: this.pageMode,
        page: this.body.page,
        scroll: window.scrollY,
      };
      switch (this.pageMode) {
        case "by name":
          body.findTextByName = this.findTextByName;
          break;
        case "by category":
          body.searchCategory = this.searchCategory;
          break;
        case "by provider":
          body.searchProvider = this.searchProvider;
          break;
      }
      sessionStorage.setItem("AdminAyanProductsMode", JSON.stringify(body));
      this.$router.push("/products/" + productId.id);
    },
    // обнуляем инпут и возвращаем товары из стора
    clearInput() {
      this.findTextByName = "";
      this.findTextByBarcode = "";
      this.searchCategory = 0;
      this.searchProvider = 0;
      this.noFindProducts = false;
      this.pageMode = "default";
      sessionStorage.removeItem("AdminAyanProductsMode");
      if (this.PRODUCT.paginatedProductsMVP.content) {
        this.items.body = this.PRODUCT.paginatedProductsMVP.content;
        this.pages.current = this.PRODUCT.paginatedProductsMVP.number;
        this.pages.count = this.PRODUCT.paginatedProductsMVP.totalPages;
      } else {
        this.setAll();
      }
      this.setChecks();
    },
    // новое назначение айдишников для чекбоксов
    setChecks() {
      this.checks = this.items.body.map(function (x) {
        return {
          state: false,
          id: x.id,
        };
      });
    },
    async setImage(val) {
      if (val.target.files[0].size > 500000) {
        ShowMessage("Размер файла превышает 500Kb");
      } else {
        let formData = new FormData();
        formData.append("files", val.target.files[0]);
        let resp = await this.productImage({
          id: this.imgId,
          files: formData,
        });
        if (resp.type !== "error") {
          ShowMessage("Изображение успешно добавлено", true);
          this.changePage(this.body.page);
        } else {
          ShowMessage(resp.data.message);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__find-block {
    width: 50%;
    display: flex;
    gap: 20px;
    justify-content: center;
    @media screen and (max-width: 1066px) {
      width: 100%;
    }
    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (max-width: 1066px) {
    flex-direction: column;
  }
}
.cell {
  max-width: 300px;
  max-height: 50px;
  &_img {
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}
.search-input {
  max-width: 40%;
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__actions {
    width: 100%;
    justify-content: right;
  }
}
</style>
